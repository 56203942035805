<template lang="pug">
Dialog(v-model:open='show')
  DialogTrigger
    slot
  DialogContent(class="sm:max-w-[425px] p-0")
    DialogHeader
      DialogTitle
      DialogDescription
    SearchBox
</template>

<script setup lang="ts">
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'

const props = defineProps<{
  show: boolean
}>()

const emit = defineEmits<{
  (e: 'update:show', val: boolean): void
}>()

const show = computed({
  set(val: boolean) {
    emit('update:show', val)
  },
  get() {
    return props.show
  },
})
</script>

<style scoped></style>
